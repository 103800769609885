.container{
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 40px 0;
}
.left{
    flex-basis: calc(65% -  30px);
    text-align: justify;
}
.left h2::after{
    left: 0;
    transform: translate(0,0);
}
.right{
    flex-basis: calc(35%);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.right img{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: 3px solid var(--brand-color);
    padding: 8px;
}

.quote{

    color: var(--brand-red);
    font-weight: bold;
    margin-top: 10px;
    text-align: right;
    display: block;
}



/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .bottom{
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    
}

/***Responsive CSS***/
@media (max-width: 640px) {
.container{
    flex-direction: column-reverse;
    row-gap: 20px;
    padding: 30px 0;
}
.left{
    text-align: center;
}
.left h2:after{
    left: 50%;
    transform: translate(-50%, 0);
}
.right{
    justify-content: center;
}

}