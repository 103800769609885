:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --header-height: 120px;
  --brand-color: #303092;
  --bg-light: #f8f8f8;
  --brand-red: #f91b29;
  --main-heading: 22px;
  --sub-heading: 18px;
  --large-heading: 26px;
  --extra-large-heading: 36px;
  --paragraph: 15px;
  --sub-paragraph: 14px;
  --small-text: 12px;
  --font-light: #3f3b3b;
  --font-dark: #1a1919;
  --font-color: #051036;
  --font-salsa: 'Salsa', sans-serif;
  --font-jost: 'Jost', sans-serif;
  --font-nobile: 'Nobile', sans-serif;
  --font-ubuntu: 'Ubuntu', sans-serif;


}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@media (max-width: 992px) {
  :root {
    --main-heading: 21px;
    --sub-heading: 17px;
    --large-heading: 23px;
    --extra-large-heading: 36px;
    --paragraph: 15px;
    --sub-paragraph: 13px;
    --small-text: 12px;
  }
}

@media (max-width: 768px) {
  :root {
    --main-heading: 20px;
    --sub-heading: 16px;
    --large-heading: 22px;
    --extra-large-heading: 26px;
    --paragraph: 14px;
    --sub-paragraph: 12px;
    --small-text: 11px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-nobile);
  font-weight: 300;
  width: 100%;
  overflow-x: hidden;
  color: var(--font-light);
}

.lazy-load-image-background {
  display: block;
  width: 100%;

}

.section-heading {
  font-size: var(--extra-large-heading);
  color: var(--font-dark);
  /* font-family: var(--font-jost); */
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
  font-family: var(--font-ubuntu);

}

.text-center {
  text-align: center;
}

.section-heading span {
  color: var(--brand-red);
}

.section-heading::after {
  bottom: 0;
  content: '';
  height: 3px;
  left: 50%;
  border-radius: 10px;
  position: absolute;
  transform: translateX(-50%);
  width: 120px;
  background-color: var(--brand-red);
}

.heading-container {
  width: 70%;
  margin: auto;
  text-align: center;
}

.section-top-heading {
  font-size: var(--sub-heading);
  text-transform: uppercase;
  font-weight: 600;
  color: var(--brand-red);

}

.section-top-heading::before {
  content: ".";
  font-size: 33px;
  margin-right: 5px;
}

.section-top-heading::after {
  content: ".";
  font-size: 33px;
  margin-left: 5px;

}

.section {
  padding: 80px 0;
  position: relative;
}

.section-paragraph {
  font-size: var(--paragraph);
  color: var(--font-light);
  white-space: break-spaces;
  text-align: justify;
  line-height: 1.6;
}

.heading-container .section-paragraph {
  text-align: center;
}

.section-button {
  margin-top: 15px;
}



a {
  color: inherit;
  text-decoration: none;
}

.myScrollbar::-webkit-scrollbar {
  width: 3px;
}

.myScrollbar::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
  width: 5px;
}

@media (min-width: 0px) {
  .container {
    padding: 0 2%;
  }

}

@media (min-width: 360px) {
  .container {
    padding: 0 2%;
  }

}

@media (min-width: 448px) {
  .container {
    padding: 0 4%;
  }

}

@media (min-width: 640px) {
  .container {
    padding: 0 4%;
  }



}

@media (min-width: 768px) {
  .container {
    padding: 0 6%;
  }

}

@media (min-width: 992px) {
  .container {
    padding: 0 6%;
  }

}

@media (min-width: 1080px) {
  .container {
    padding: 0 8%;
  }

}

@media (max-width: 640px) {
  .heading-container {
    width: 100% !important;
  }

  .section {
    padding: 35px 0;

  }



}

.container {
  transition: all 500ms ease;
  margin: auto;
  width: 100% !important;
  max-width: 100% !important;
}




/* @media (min-width: 640px) {

  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {

  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {

  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {

  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {

  .container {
    max-width: 1536px;
  }
} */