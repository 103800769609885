.outer{
}
.inner{}
.coursesContainer{
    margin-top: 30px;
    padding: 0 70px;
}
.inner{
    text-align: center;
}




/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .coursesContainer {
        padding: 0 30px;
    }

}