.outer {
    /* background-color: var(--bg-light); */
    background-color: #fff;
    position: relative;
    z-index: 6;
    width: 100%;
}

.inner {
    position: relative;
    display: flex;
}

.left {
    width: 60%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;

}

.imageWrapper {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.aboutImage {
    height: 400px;
    width: 400px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
}

.top {
    width: 40%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.top h2::after {
    left: 0;
    transform: translate(0, 0);
}

.button {
    margin-top: 30px;
}





.boundingBox {
    width: 500px;
    height: 500px;
    margin: 0 auto;
    margin-left: auto;
    position: absolute;
    animation: jss32 linear 36s infinite;
    margin-left: 0;
    border-radius: 100%;
    max-width: 100%;

}

.boundingBoxIn1 {
    top: -10px;
    left: -10px;
    color: #fff;
    width: 500px;
    height: 500px;
    display: block;
    position: absolute;
    animation: jss26 linear 7s infinite;
    background: linear-gradient(to left, rgba(48, 48, 146, 0.3) 0%, rgba(48, 48, 146, 0.6) 100%);
    text-align: center;
    border-radius: 100%;
    max-width: 100%;
}

.boundingBoxIn2 {
    top: -10px;
    left: -10px;
    width: 500px;
    height: 500px;
    animation: jss27 linear 7s infinite;
    border-radius: 100%;
    background: linear-gradient(to left, rgba(48, 48, 146, 0.3) 0%, rgba(48, 48, 146, 1) 100%);
    position: absolute;
    color: #fff;
    display: block;
    text-align: center;
    max-width: 100%;


}

.boundingBoxIn3 {
    top: 10px;
    left: -10px;
    width: 500px;
    height: 500px;
    animation: jss28 linear 10s infinite;
    background: linear-gradient(to left, rgba(48, 48, 146, 0.3) 0%, rgba(48, 48, 146, 1) 100%);
    position: absolute;
    border-radius: 100%;
    color: #fff;
    display: block;
    text-align: center;
    max-width: 100%;

}

.boundingBoxIn4 {
    top: -10px;
    left: 10px;
    width: 500px;
    height: 500px;
    animation: jss29 linear 7s infinite;
    border-radius: 45%;
    background: linear-gradient(to left, rgba(48, 48, 146, 0.3) 0%, rgba(48, 48, 146, 1) 100%);
    position: absolute;
    color: #fff;
    display: block;
    text-align: center;
    max-width: 100%;

}

.spinningContainer {
    width: 400px;
    height: 400px;
    margin: 0 auto;
    margin-left: auto;
    display: block;
    z-index: 9;
    position: absolute;
    margin-left: 0;
    max-width: 100%;
}

.spinningBox1 {
    width: 400px;
    height: 400px;
    animation: jss35 7s infinite linear;
    margin-left: 50px;
    position: absolute;
    max-width: 100%;
}

.spinningBox1::before {
    content: '';
    position: absolute;
    font-size: 0px;
    border-radius: 100%;
    padding: 5px;
    background: #00b8de;
    max-width: 100%;
}

.spinningBox2 {
    width: 500px;
    height: 500px;
    animation: jss35 9s infinite linear;
    margin-left: 0px;
    position: absolute;
    max-width: 100%;
}

.spinningBox2::before {
    animation: jss33 10s infinite cubic-bezier(1.000, -0.400, 0.100, 1.210);
    background: #e97b7a;
    margin-top: 50px;
    margin-left: 60px;
    content: '';
    position: absolute;
    font-size: 0px;
    border-radius: 100%;
    padding: 5px;
    max-width: 100%;
}

.spinningBox3 {
    width: 500px;
    height: 500px;
    animation: jss35 6s infinite linear;
    margin-left: 50px;
    position: absolute;
    max-width: 100%;
}

.spinningBox3::before {
    content: '';
    font-size: 0px;
    border-radius: 100%;
    border: 3px solid #c7d948;
    padding: 6px;
    position: absolute;
    animation: jss36 9s infinite linear;
    max-width: 100%;
}

.spinningBox4 {
    width: 500px;
    height: 500px;
    animation: jss36 9s infinite linear;
    position: absolute;
    max-width: 100%;
}

.spinningBox4::before {
    content: '';
    position: absolute;
    font-size: 0px;
    border-radius: 100%;
    border: 3px solid #e97b7a;
    padding: 6px;
    animation: jss34 5s infinite;
    animation-delay: 0s;
    animation-delay: 6s;
    max-width: 100%;
}

@keyframes jss33 {
    0% {
        transform: scale(.6);
    }

    100% {
        transform: scale(.1);
    }

}

@keyframes jss36 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

@keyframes jss35 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

}

@keyframes jss34 {
    0% {
        opacity: 1;
    }

    100% {
        opacity: .1;
    }

}

@keyframes jss29 {
    0% {
        top: 0px;
        left: 0px;
        transform: rotate(0deg);
    }

    50% {
        top: -5px;
        left: -10px;
        transform: rotate(180deg);
    }

    100% {
        top: 0px;
        left: 0px;
        transform: rotate(360deg);
    }
}

@keyframes jss28 {
    0% {
        top: 0px;
        left: 0px;
        transform: rotate(0deg);
    }

    50% {
        top: 5px;
        left: 15px;
        transform: rotate(180deg);
    }

    100% {
        top: 0px;
        left: 0px;
        transform: rotate(360deg);
    }
}

@keyframes jss27 {
    0% {
        top: 0px;
        transform: rotate(0deg);
    }

    50% {
        top: -10px;
        transform: rotate(-180deg);
    }

    100% {
        top: 0px;
        transform: rotate(-360deg);
    }

}

@keyframes jss26 {
    0% {
        top: 0px;
        transform: rotate(0deg) scale(1);
    }

    50% {
        top: 10px;
        transform: rotate(180deg) scale(1.04);
    }

    100% {
        top: 0px;
        transform: rotate(360deg) scale(1);
    }
}

@keyframes jss32 {
    0% {
        transform: rotate(0deg) skewX(0deg);
    }

    100% {
        transform: rotate(-360deg) skewX(0deg);
    }

}


/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {


    .aboutImage {
        height: 300px;
        width: 300px;
    }



    .spinningBox1 {
        width: 300px;
        height: 300px;
    }

    .spinningBox2 {

        width: 400px;
        height: 400px;
    }

    .spinningBox3 {

        width: 400px;
        height: 400px;
    }

    .spinningContainer {
        width: 300px;
        height: 300px;
    }

    .left {
        height: 500px;

    }

    .boundingBox {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn1 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn2 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn3 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn4 {
        height: 400px;
        width: 400px;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .inner {
        flex-direction: column;
        row-gap: 0px;
    }

    .aboutImage {
        height: 300px;
        width: 300px;
    }

    .top {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        text-align: center;
    }

    .spinningBox1 {
        width: 300px;
        height: 300px;
        margin-left: 0;
    }

    .spinningBox2 {

        width: 400px;
        height: 400px;
    }

    .spinningBox3 {

        width: 400px;
        height: 400px;
        margin-left: 0;
    }

    .spinningBox4 {

        width: 400px;
        height: 400px;
    }


    .spinningContainer {
        width: 300px;
        height: 300px;
    }

    .left {
        height: 500px;
        width: 100%;

    }

    .top {
        width: 100%;
    }

    .boundingBox {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn1 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn2 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn3 {
        height: 400px;
        width: 400px;
    }

    .boundingBoxIn4 {
        height: 400px;
        width: 400px;
    }
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .inner {
        row-gap: 0px;
    }

    .top {
        width: 100%;
    }

    .left {
        width: 100%;
        height: 400px;
    }

    .aboutImage {
        height: 250px;
        width: 250px;
    }

    .inner {
        flex-direction: column;
        row-gap: 0px;
    }

    .spinningBox1 {
        width: 200px;
        height: 200px;
    }

    .spinningBox2 {

        width: 300px;
        height: 300px;
    }

    .spinningBox2::before {
        margin-left: 0;
    }

    .spinningBox3 {

        width: 300px;
        height: 300px;
        margin-left: 0;
    }

    .spinningBox4 {

        width: 300px;
        height: 300px;
    }


    .spinningContainer {
        width: 300px;
        height: 300px;
    }



    .boundingBox {
        height: 300px;
        width: 300px;
    }

    .boundingBoxIn1 {
        height: 300px;
        width: 300px;
    }

    .boundingBoxIn2 {
        height: 300px;
        width: 300px;
    }

    .boundingBoxIn3 {
        height: 300px;
        width: 300px;
    }

    .boundingBoxIn4 {
        height: 300px;
        width: 300px;
    }

}




/***Responsive CSS***/
@media (max-width: 448px) {
    .inner {
        row-gap: 0px;
    }

    .top {
        width: 100%;
    }

    .left {
        width: 100%;
        overflow-x: clip;
        height: 400px;
    }

    .aboutImage {
        height: 220px;
        width: 220px;
    }

    .inner {
        flex-direction: column;
        row-gap: 0px;
    }

    .spinningBox1 {
        width: 180px;
        height: 180px;
    }

    .spinningBox2 {

        width: 270px;
        height: 270px;
    }


    .spinningBox3 {

        width: 270px;
        height: 270px;
        margin-left: 0;
    }
    .spinningBox4 {

        width: 270px;
        height: 270px;
    }

    .spinningContainer {
        width: 270px;
        height: 270px;
    }



    .boundingBox {
        height: 270px;
        width: 270px;
    }

    .boundingBoxIn1 {
        height: 250px;
        width: 250px;
    }

    .boundingBoxIn2 {
        height: 270px;
        width: 270px;
    }

    .boundingBoxIn3 {
        height: 250px;
        width: 250px;
    }

    .boundingBoxIn4 {
        height: 270px;
        width: 270px;
    }

}