.investmentPage {}

.investmentHero {
    background-color: rgba(48, 48, 146, 0.8);
    padding: 80px 0;
}

.investmentHeroInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
}

.left {
    width: calc(50% - 30px);
}

.left p {
    color: #fff;
}

.left h2 {
    color: #fff;
}

.left h2::after {
    left: 0;
    transform: translate(0);
    background-color: #fff;
}

.right {
    width: 50%;
    position: relative;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.image1 {
    position: absolute;
    top: 0;
    left: 30px;
    width: 200px;
    height: 200px;
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
}

.image2 {
    position: absolute;
    right: 30px;
    top: 0;
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
    height: 230px !important;
    width: 230px !important;
}

.image3 {
    position: absolute;
    bottom: -21px;
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
    width: 300px !important;
    height: 300px !important;
}

.galleryOuter {
    padding: 100px 0;
}

.galleryInner {
    column-count: 4;
    column-gap: 5px;
    row-gap: 5px;
}

.galleryInner img {
    width: 100%;
    box-shadow:-3px 5px 15px #000;

}


/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/
@media (max-width: 992px) {

    .galleryInner {
        column-count: 3;
    }
    .right{
        height: 400px;
    }
    
    .image1 {
        top: 30px;
        left: 0px;
    }

    .image2 {
        right: 30px;
        top: 0;
        height: 130px !important;
        width: 130px !important;
    }

    .image3 {
        bottom: 0px;
        width: 180px !important;
        height: 180px !important;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .investmentHeroInner {
        flex-direction: column-reverse;
    }


    .left h2::after {
        left: 50%;
        transform: translateX(-50%);
    }

    .left {
        text-align: center;
    }

    .investmentHero {
        padding: 30px 0;
    }

    .investmentForm {
        padding: 30px 0;
    }

    .left img {
        height: 300px;
    }

    .left {
        width: 100%;
    }

    .right {
        height: 300px;
        width: 100%;
    }

    

    .image1 {
        top: 0;
        left: 60px;
        
        height: 100px !important;
        width: 100px !important;
    }

    .image2 {
        right: 60px;
        top: 0;
        height: 130px !important;
        width: 130px !important;
    }

    .image3 {
        bottom: 0px;
        width: 180px !important;
        height: 180px !important;
    }

    .galleryInner {
        column-count: 2;
    }
}

/***Responsive CSS***/
@media (max-width: 448px) {}