.courseBox{
    text-align: left;
    /* box-shadow: 0px 0px 1px 1px #000; */
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    position: relative;
    height: 350px;
}
.courseBox:hover .cardDetail{
    height: 150px;
}
.courseBox img{
    width: 100%;
    height: 350px;
    object-fit: cover;
}
.cardDetail{
    padding: 10px 15px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    width: 100%;
    height: 80px;
    overflow: hidden;
    transition: all 500ms ease;

}
.cardDetail::after{
    position: absolute;
    content: "";
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    z-index: -1;
    height: 100%;
    bottom: 0;
    left: 0;
}
.name{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 6px;
    height: 23px;
    font-size: 18px;
    color: #fff;
    font-family: var(--font-ubuntu);

}
.cardInfo{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 6px;
    font-size: 15px;
}
.cardDuration{
    font-size: var(--sub-paragraph);
    color: #fff;
    background-color: var(--brand-color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 3px 15px;
    border-radius: 15px;
    /* margin-left: auto; */

}
.button{
    margin-top: 20px;
}

.button a{
    width: 100%;
    display: flex;
}





/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .cardDetail{
        height: 150px;
    }

}