.yarl__navigation_prev{
    padding: 1px;
    background-color: var(--brand-color);
    border-radius: 50%;
}

.yarl__navigation_next{
    padding: 1px;
    background-color: var(--brand-color);
    border-radius: 50%;
}