.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container h3{
    font-size: var(--large-heading);
}
.container img{
    width: 100%;
    margin: 30px 0;
}
.successHeading{
    color: var(--brand-color);

}
.failureHeading{
    color: var(--brand-red);

}
.container p{
    text-align: center;
}