.footerTop {
    background-color: var(--bg-light);
    padding: 50px 0;
}

.footerTopInner {
    display: flex;
    justify-content: space-between;
}

.footerBox {
    display: flex;
    align-items: center;
    column-gap: 30px;
}

.icon {
    color: var(--brand-color);
    font-size: 62px;
    display: flex;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.title {
    font-weight: bold;
    font-size: var(--sub-header);
    color: var(--font-dark);
}

.text {
    font-size: var(--paragraph);
    color: var(--font-light);
}







/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .footerTopInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 30px;
    }

    .footerBox {
        display: flex;
        align-items: center;
        flex-direction: column;
        column-gap: 30px;
        row-gap: 20px;
        text-align: center;
    }
    .icon {
        font-size: 42px;

    }
}