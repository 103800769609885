.outer{
    background-color: var(--bg-light);
    padding: 80px 0;
}
.inner{}

.bottom{
    width: 85%;
    margin: auto;
    margin-top: 40px;
}
.cardsContainer{
    display: flex;
    column-gap: 30px;
    row-gap: 40px;
    flex-wrap: wrap;

}
.card{
    text-align: center;
    border-bottom: 3px solid var(--brand-color);
    padding: 20px 10px;
    box-shadow: 0 0 1px 1px #ccc;
    flex-basis: calc(33.33% - 20px);
    background-color: #fff;
    flex-grow: 1;
}
.title{
    margin-top: 20px;
    font-family: var(--font-ubuntu);

}
.paragraph{
    margin-top: 15px;
}
.card img{
    width: 100%;
    height: 100px;
    object-fit: contain;
}



/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .bottom{
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .cardsContainer{
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .bottom{
        margin-top: 20px;
    }
    .card{
        flex-basis: 100%;
    }
    .top{
        width: 100%;
    }
    .outer{
        padding: 30px 0;
    }

}