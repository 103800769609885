.inner{
}
.section{
    background-color: var(--bg-light);

}
.sliderOuter{

    width:40%;
    margin: auto;
    margin-top: 20px;
}
.item{
    background-color: var(--brand-color);
    color: #fff;
    padding: 30px 50px;
    border-radius: 20px;
    display: flex!important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.item img{
    width: 250px;
    height: 250px;
    object-fit: contain;
    margin-bottom: 30px;
}
.item h3{
    text-align: center;
    margin-bottom: 10px;
    font-family: var(--font-ubuntu);

}
.item p{
    text-align: center;
    color: #fff;
}



/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .sliderOuter{
        width: 60%;
    }
    .item{
    }
       
}

/***Responsive CSS***/
@media (max-width: 768px) {
.sliderOuter{
    width: 70%;
}
.item{

}
}

/***Responsive CSS***/
@media (max-width: 640px) {
    
    .sliderOuter{
        width: 90%;
    }
    .item{
    
    }
    .section{
        padding-bottom: 100px;
    }
}


/***Responsive CSS***/
@media (max-width: 448px) {
    
    .sliderOuter{
        width: 100%;
    }
    .item{
        padding: 30px 30px;

    }
}