/* .requestModal{
    background-color: #fff;
    box-shadow: 0 0 3px 1px #ccc;
    width: 40%;
    margin: auto;
    height: 100vh;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 30px;

}
.requestModalInner{
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 15px 20px;
    position: relative;


} */
.cross{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .requestModal{
        width: 50%;
     }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .requestModal{
        width: 70%;
     }
}

/***Responsive CSS***/
@media (max-width: 640px) {
 .requestModal{
    width: 90%;
 }
}