.outer{
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 80px 0;
    position: relative;
    z-index: 0;
}
.outer::after{
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    /* background-color: rgba(48,48,146,0.9); */
    background-color: rgba(0, 0, 0, 0.85);

}
.inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: auto;
    text-align: center;
    color: #fff;
}
.inner h2{
    color: #fff;
}
.inner h2::after{
    background-color: #fff;
}
.inner p{
    color: #fff;
    text-align: center;
}
.button{
    margin-top: 30px;
}






/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .inner{
        width: 80%;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .inner{
        width: 90%;
    }
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .inner{
        width: 100%;
    }
    .outer{
        padding: 30px 0;
    }
    

}