.outer{
    padding: 20px 0 80px;
    background-color: var(--bg-light);
}
.inner{

}
.coursesContainer{
    display: flex;
    column-gap: 3%;
    flex-wrap: wrap;
    margin-top: 50px;
}
.coursesContainer>div{
    flex-basis: 30%;

}




/***Responsive CSS***/
@media (min-width: 1024px) {
    .inner{
        margin: 0!important;
        padding: 0 30px !important;
    }
}
/***Responsive CSS***/

@media (max-width: 992px) {
    
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .outer{
        padding: 20px 0 40px;
    }
    .coursesContainer{
        margin-top: 30px;
    }
    .coursesContainer>div{
        flex-basis: 48%;
    
    }
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .coursesContainer{
        margin-top: 20px;
    }
    .coursesContainer>div{
        flex-basis: 100%;
    
    }
    .outer{
        padding: 20px 0 20px;
    }
    
}