.productInfo {
    padding: 80px 0 80px;
    background-color: var(--bg-light);
}

.inner {
    display: flex;
    column-gap: 40px;
    align-items: center;
}

.left {
    flex-basis: 45%;
}

.left h2::after {
    left: 0;
    transform: translate(0, 0);
}

.left p {
    margin-bottom: 30px;
}

.right {
    flex-basis: calc(55% - 40px);
}

.right img {
    width: 100%;
    object-fit: cover;
    max-height: 400px;
}


/***Responsive CSS***/
@media (min-width: 1024px) {
    .inner{
        margin: 0!important;
        padding: 0 30px !important;
    }
}

/***Responsive CSS***/

@media (max-width: 992px) {
 
}

/***Responsive CSS***/
@media (max-width: 768px) {

}

/***Responsive CSS***/
@media (max-width: 640px) {
    .productInfo{
        padding: 30px 0;
    }
    .inner {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        text-align: center;
        row-gap: 20px;
    }

    .left h2::after {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .right{
        width: 100%;
    }
}