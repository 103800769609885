.contactPage{}
.contactHero{
    background-color: var(--bg-light);
    padding: 80px 0;
}
.contactHeroInner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
}
.left{
    flex-basis: calc(50% - 30px);
}
.left img{
    width: 100%;
    height: 450px;
    object-fit: contain;
}
.left p{
}
.left h2{
}
.left h2::after{
    left: 0;
    transform: translate(0);
}
.right{
    flex-basis: 50%;
}
.right img{
    width: 100%;
    height: 450px;
    object-fit: contain;
}
.right h2::after{
    left: 0;
    transform: translate(0);
}

.contactForm{
    padding: 80px 0;

}
.contactFormInner{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
}
.contactFormInner h2{
    /* text-align: center; */
}
.contactFormInner p{
    /* text-align: center; */
}
.contactFormContainer{
    width: 100%;
    margin: auto;
    margin-top: 30px;
}
.contactFormContainer form{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 20px;
}
.inputContainer{
position: relative;
}
.inputContainer .required{
    color: red;
    margin-left: 4px;
}
.inputContainer label{
    color: var(--brand-color);
}
.inputContainer input{
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
}
.inputContainer .errorText{
    color: red;
    font-size: 12px;
}
.inputContainer input:focus-visible{
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}

.inputContainer textarea{
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer textarea:focus-visible{
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}










  

  /***Responsive CSS***/
  @media (min-width: 1024px) {
  
  }

    /***Responsive CSS***/
    @media (max-width: 992px) {
    
    
    }
    
      /***Responsive CSS***/
  @media (max-width: 768px) {
    .contactHeroInner{
        flex-direction: column-reverse;
    }
    .left{
        flex-basis: 100%;
    }
    .right{
        flex-basis: 100%;
    }
 }
 
 /***Responsive CSS***/
 @media (max-width: 640px) {
    .contactHero{
        padding: 30px 0;
    }
    .contactForm{
        padding: 30px 0;
    }
    .left img{
        height: 300px;
    }
    .right img{
        height: 300px;
    }
}
/***Responsive CSS***/
@media (max-width: 448px) {
    
}

