.container{
    display: flex;
    column-gap: 30px;
    align-items: center;
padding: 40px 0;
}
.left{
    flex-basis: calc(60%);

}

.right{
    flex-basis: calc(40% -  30px);

}
.right h2::after{
    left: 0;
    transform: translate(0,0);
}

.left img{
    width: 100%;
    object-fit: contain;
    max-height: 450px;
}



/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .bottom{
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .container{
        flex-direction: column;
        row-gap: 20px;
        padding: 30px 0;
    }
    .right{
        text-align: center;
    }
    .right h2:after{
        left: 50%;
        transform: translate(-50%, 0);
    }
}