.mainButton {
    height: 39px;
    line-height: 48px;
    font-size: 18px;
    padding: 0 20px;
    background: var(--brand-red);
    color: #ffffff;
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid var(--brand-red);
    font-family: var(--font-jost);
}

.mainButton:hover {
    background: #000;
    color: #fff;
    border: 1px solid #000;

}


.alternateButton {
    height: 39px;
    line-height: 48px;
    font-size: 18px;
    padding: 0 20px;
    background: #000;
    color: #ffffff;
    position: relative;
    z-index: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid #000;
    font-family: var(--font-salsa);

}

.alternateButton:hover {
    background: var(--brand-color);
    color: #fff;
    border: 1px solid var(--brand-color);

}