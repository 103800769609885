.footerContainer {
    /* background-color: var(--brand-color); */
    position: relative;
    z-index: 1;
    background-image: url('/public/Images/Footer/footbg.jpeg');
}
.footerContainer::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.85);
}

.footerInner {}

.headerTop {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0;
    border-bottom: 1px solid #dddddd48;
    row-gap: 25px;
}

.headerAbout {
    flex-basis: 30%;
    color: #fff;
    
}
.headerAbout p{
    color: #fff;
    margin-top: -20px;
}

.headerAbout img {
    width: 350px;
    height: 120px;
    object-fit: contain;
    position: relative;
    top: -20px;
}
.footerLinks{
    flex-basis: 15%;
}
.footerHeader{
    color: #fff;
    margin-bottom: 20px;
}
.linksBox{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    color: #fff;
}
.linksBox a{
    padding: 6px 0;
}
.linksBox a:hover{
    color: #000;
}
.footerContact {
    color: #fff;
    flex-basis: 28%;
    
}
.coursetFooter .footerContact{
    flex-basis: 33%;
}

.footerContact ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 8px;
}

.footerContact ul li {
    display: flex;
    column-gap: 10px;
    align-items: center;
    row-gap: 13px;
    line-height: 1.6;

}
.footerContact a{
    font-size: var(--large-heading);
}
.contactIcon {
    font-size: var(--sub-header);

}
.coursetFooter .footerContact{
    flex-basis: 35%;
}
.contactText {
    font-size: var(--sub-paragraph);

}

.footerIcons {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

}

.iconBox {
    display: flex;
    flex-wrap: wrap;
    font-size: var(--paragraph);
    column-gap: 20px;
    color: #fff;
}
.iconBox a{
    display: inline-block;
}
.iconBox a svg {
    font-size: var(--sub-header);
    transition: all 500ms ease;
}
.iconBox a:hover{
    transform: scale(1.1);
}

.headerBottom {
    padding: 15px 0;
    font-size: var(--sub-paragraph);
    color: #fff;
}
.headerBottom a{
    color: var(--brand-red);
    transition: all 500ms ease;
    display: inline-block;
}
.headerBottom a:hover{
    transform: scale(1.1);
}

.acceptContainer{
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}
.acceptContainer h3{
    font-size: var(--paragraph);
    color: #fff;
}
.acceptBox {
    display: flex;
    column-gap: 10px;
    row-gap: 6px;
    align-items: center;
    flex-wrap: wrap;
}

.accept {}


.headerBottom{
    line-height: 1.6;
}

/***Responsive CSS***/
@media (min-width: 1024px) {
    .courseFooter .footerInner{
        margin: 0!important;
        padding: 0 30px !important;
    }
}

@media (max-width: 992px) {
    .headerAbout {
        flex-basis: 100%;
    }
    .footerLinks{
        flex-basis: 50%;
    }
    .footerContact {
        color: #fff;
        flex-basis: 50%;
    
    }
}


@media (max-width: 768px) {
    .headerBottom{
        text-align: center;
        padding: 20px 20px;
    }
    .headerAbout {
        flex-basis: 100%;
    }
    .footerLinks{
        flex-basis: 50%;
    }
    .footerContact {
        color: #fff;
        flex-basis: 50%;
    
    }
    
}

@media (max-width: 640px) {
    .headerBottom{
        text-align: center;
        padding: 20px 20px;
    }
    .headerAbout {
        flex-basis: 100%;
    }
    .footerLinks{
        flex-basis: 100%;
    }
    .footerContact {
        color: #fff;
        flex-basis: 100%;
    
    }
    
}