

.navContainer{
    display: none;
    justify-content: flex-end;
}
.navBack{
    position: fixed;
    background-color: var(--brand-color);
    left: 0;
    top: 0;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    padding: 40px 20px;
}
.hamburger{
    font-size: 22px;
    cursor: pointer;

}
.navList{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 20px;
    row-gap: 20px;
    flex-direction: column;
}
.close{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 35px;
    color: #fff;
}
.close svg{
    fill: #fff;
    cursor: pointer;
}
.navList a{
    color: #fff;
}
@media (max-width: 768px) {
    .navContainer{
        display: flex;
    }
    
}