.landingInvest {
    background-color: #fff;
}
.inner{
    display: flex;
    column-gap: 30px;
    align-items: center;
    padding: 40px 0;
}
.right{
    flex-basis: calc(50% -  30px);
}
.right h2::after{
    left: 0;
    transform: translate(0,0);
}
.left{
    flex-basis: calc(50%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.left img{
    width: 100%;
    object-fit: contain;
}
.brochureButton{
    margin-top: 20px;
}

/***Responsive CSS***/
@media (min-width: 1024px) {
    .inner{
        margin: 0!important;
        padding: 0 30px !important;
    }
}

/***Responsive CSS***/

@media (max-width: 992px) {
    .bottom{
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    
}

/***Responsive CSS***/
@media (max-width: 640px) {
.inner{
    row-gap: 20px;
    padding: 30px 0;
    flex-wrap: wrap;
    
}
.right{
    text-align: center;
    flex-basis: 100%;
}
.left{
    flex-basis: 100%;

}
.right h2:after{
    left: 50%;
    transform: translate(-50%, 0);
}
.right{}

}