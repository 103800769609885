.outer {
    background-color: var(--bg-light);
    padding: 80px 0;
}

.inner {}

.reviewsContainer {
    margin-top: 30px;

}

.reviewsSlick {
    padding: 0 75px;

}

.reviewCard {
    padding: 30px 0px 0;
    color: #fff;
}

.reviewInner {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    background-color: var(--brand-color);
    padding: 0px 20px 40px;
    border-radius: 10px;


}

.reviewCard img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
}

.rating {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.details {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 90px;
    margin-bottom: 10px;
    line-height: 1.6;
}

.name {
    margin-bottom: 5px;
    font-weight: bold;
    font-family: var(--font-ubuntu);
}

.time {
    font-size: 13px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}




/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .reviewsSlick {
        padding: 30px;
    }
    .outer {
        padding: 30px 0;
    }

    .reviewsContainer {
        margin-top: 20px;
    
    }
    
    .reviewsSlick {
        padding: 0 35px;
    
    }

}