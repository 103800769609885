.formContainer{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 20px;
}

.inputContainer .required{
    color: red;
    margin-left: 4px;
}
.inputContainer label{
    color: var(--brand-color);
}
.inputContainer .errorText{
    color: red;
    font-size: 12px;
}
.inputContainer input{
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer input:focus-visible{
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}

.inputContainer textarea{
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer textarea:focus-visible{
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}

