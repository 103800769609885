.preloaderContainer{}
.preloader{
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease;
}
.loaderOn{
    transform: translateY(0);
    opacity: 1;
    z-index: 1000;
}
.loaderOff{
    opacity: 0;
    transform: translateY(-150%);
    z-index: -1000;
}
.logo{
    width: 450px;
    height: 250px;
    animation: blink 1s linear 0s infinite alternate;
}
.logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@keyframes blink {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
    
}
@media (max-width: 640px) {
    .logo{
        width: 250px;
        height: 250px;
    }
    
}