.hero-slick-container {}

.hero-slick-container .slick-dots {
    position: absolute;
    bottom: 40px;
    display: inline;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.hero-slick-container .slick-dots button::before {

    top: 50%;
    left: 0;
    transform: translate(-50%, 0%);
    height: 5px;
    width: 30px;
}

.hero-slick-container .slick-dots .slick-active button::before {
    color: var(--brand-color);
    font-size: 20px;

}



.hero-slick-container .slick-arrow.slick-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    z-index: 5;
    transform: translate(0%, -50%);
    background: rgba(0,0,0,0.5);
    height: 120px;
    width: 40px;
    border-radius: 10px;

}

.hero-slick-container .slick-arrow.slick-next {
    position: absolute;
    right: 20px;
    top: 50%;
    z-index: 5;
    transform: translate(0%, -50%);
    background: rgba(0,0,0,0.5);
    height: 120px;
    width: 40px;
    border-radius: 10px;

}

/***Responsive CSS***/
@media (max-width: 448px) {
    .hero-slick-container {
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 640px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}


/***Responsive CSS***/
@media (max-width: 992px) {}


/***Responsive CSS***/
@media (min-width: 1024px) {}