.navContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    column-gap: 20px;

}
.navItem{
padding: 5px 0;
color: var(--font-dark);
position: relative;
}
.navItem::before{
    content: "";
    background-color: var(--brand-color);
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 5px;
    width: 0%;
    transition: all 500ms ease;
}
.navItem::after{
    content: "";
    background-color: var(--brand-red);
    position: absolute;
    right: 0;
    bottom: -2px;
    height: 5px;
    width: 0%;
    transition: all 500ms ease;
}
.navItem:hover{
    color: var(--brand-red);
}
.navItem:hover::before{
    width: 50%;
}
.navItem:hover::after{
    width: 50%;
}
.selected{
    color: var(--brand-red);
}
.selected::before{
    width: 50%;
}
.selected::after{
    width: 50%;
}

@media (max-width: 768px) {
    .navContainer{
        display: none;
    }
    
}