.reviews-slick-container {
}

.reviews-slick-container .slick-slide>div{
    margin: 0 10px;
}

.reviews-slick-container .slick-list{
    margin: 0 -10px;
    padding-top: 40px!important;
}

.reviews-slick-container .slick-dots {
    position: absolute;
    bottom: -50px;
    display: inline;
    left: 0px;
    width: 100px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.reviews-slick-container .slick-dots button::before {

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 5px;
    width: 30px;
}

.reviews-slick-container .slick-dots .slick-active button::before {
    color: var(--brand-color);
    font-size: 20px;

}


.reviews-slick-container .slick-arrow.slick-prev {
    position: absolute;
    left: 20px;
    top: 50%;
    z-index: 5;
    transform: translate(0%, -50%);
    background: rgba(48,48,146,0.9);
    height: 120px;
    width: 40px;
    border-radius: 10px;

}

.reviews-slick-container .slick-arrow.slick-next {
    position: absolute;
    right: 20px;
    top: 50%;
    z-index: 5;
    transform: translate(0%, -50%);
    background: rgba(48,48,146,0.9);
    height: 120px;
    width: 40px;
    border-radius: 10px;

}
.reviews-slick-container .slick-slide{
   transition: all 1s ease;
    }
.reviews-slick-container .slick-slide.slick-active.slick-center.slick-current{
transform: translateY(-30px);
transition: all 1s ease;

}

/***Responsive CSS***/
@media (max-width: 448px) {
    .reviews-slick-container {
        width: 100%;
    }
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .reviews-slick-container .slick-arrow.slick-prev {
   left: 0px;
        height: 80px;
        width: 30px;
    
    }
    
    .reviews-slick-container .slick-arrow.slick-next {
        right: 0px;
        height: 80px;
        width: 30px;
    
    }

    .reviews-slick-container .slick-list{
        margin: 0 -10px;
        padding-top: 10px!important;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {}


/***Responsive CSS***/
@media (max-width: 992px) {}


/***Responsive CSS***/
@media (min-width: 1024px) {}