.outer{
    padding: 80px 0;
}
.inner{}
.galleryContainer{
    margin-top: 50px;
    width: 65%;
    margin: auto;
}
.galleryGrid{
    display: grid;
    row-gap: 10px;
    column-gap: 10px;
    cursor: pointer;
}
.galleryGrid:hover{
    opacity: 0.95;
}
.gridItem{
}
.gridItem img{
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.wrapper{
    width: 100%;
    height: 100%;
}
.gridItem1{
   grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 400px;

}
.gridItem2{

   grid-column-start: 3;
   grid-column-end: 4;
   grid-row-start: 1;
   grid-row-end: 2;
   height: 200px;

}
.gridItem3{

   grid-column-start: 1;
   grid-column-end: 2;
   grid-row-start: 3;
   grid-row-end: 4;    
   height: 200px;

}
.gridItem4{

   grid-column-start: 2;
   grid-column-end: 3;
   grid-row-start: 3;
   grid-row-end: 4;    
   height: 200px;

}

.gridItem5{
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 4;    
    height: 400px;
 
 }






/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .gridItem1{
        height: 250px;
    }
    .gridItem2{
        height: 150px;
    }
    .gridItem3{
        height: 150px;
    }
    .gridItem4{
        height: 150px;
    }
    .gridItem5{
        height: 250px;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .galleryContainer{
        margin-top: 20px;
        width: 80%;
    }
    
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .outer{
        padding: 30px 0;
    }
    .gridItem1{
        height: 250px;
    }
    .gridItem2{
        height: 150px;
    }
    .gridItem3{
        height: 150px;
    }
    .gridItem4{
        height: 150px;
    }
    .gridItem5{
        height: 250px;
    }
    .galleryContainer{
        width: 70%;
    }

}


@media (max-width: 640px) {
.galleryContainer{
    width: 100%;
}
}