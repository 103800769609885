.aboutHero{
    background-color: var(--bg-light);
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 1;
    position: relative;
    
}
.aboutHero::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #f8f8f8d6;
    z-index: -1;
}
.landingABoutInner{
    
}
.storyInner{
    width: 70%;
    margin: auto;
}
.left{
    flex-basis: 50%;
}
.left img{
    width: 100%;
    height: 600px;
    object-fit: cover;
}

.storyInner h2{
    text-align: center;
}
.storyInner p{
    text-align: justify;
}
.message{
    background-color: var(--bg-light);
}

.team{}
.teamInner{}
.sectionHeader{
    text-align: center;
    width: 60%;
    margin: auto;
    
}
.teamContainer{
    display: flex;
    column-gap: 20px;
    row-gap: 40px;
    margin-top: 40px;
    flex-wrap: wrap;
}
.teamCard{
    flex-basis: calc(25% - 20px);
    position: relative;
    background-color: var(--bg-light);
    padding: 10px 10px;
}
.teamImage{
    height: 250px;
    width: 100%;
}
.teamImage img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.teamDesc{
    margin-top: 15px;
}
.teamDesc h3{
    color: var(--font-dark);
    font-size: 16px;
    font-family: var(--font-ubuntu);

    
}
.teamDesc p{
    color: var(--font-light);
    margin-top: 6px;
    font-size: 14px;
}
.teamSocial{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -5;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
    
}
.teamCard:hover .teamSocial{
    opacity: 1;
    z-index: 5;
}
.socialTitle{
    color: #fff;
    font-size: var(--main-heading);

}
.socialBody{
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    column-gap: 5px;

}
.socialItem{
    color: #000;
    background-color: #d5cfcf;
    border: 1px solid #fff;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: var(--paragraph);
    transition: all 500ms ease;
}
.socialItem:hover{
    color: #fff;
    background-color: #316FF6;
}





/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .cardsContainer {
        width: 80%;

    }
}

/***Responsive CSS***/
@media (max-width: 768px) {

    .cardsContainer {
        width: 80%;

    }
    .left{
        width: 100%;
    }
    .storyInner{
        width: 80%;
    }


}

/***Responsive CSS***/
@media (max-width: 640px) {
    .landingABoutInner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        row-gap: 20px;
    }

    .right h2::after {
        left: 50%;
        transform: translate(-50%, 0);
    }
    .right{
        width: 100%;
    }
    .teamContainer{
        column-gap: 10px;
        row-gap: 15px;
    }
    .teamCard{
        flex-basis: calc(50% - 10px);
    }
    .storyInner{
        width: 90%;
    }
   
}
@media (max-width: 448px) {
    .teamImage{
        height: 180px;
        width: 100%;
    }
    .storyInner{
        width: 100%;
    }
    
}