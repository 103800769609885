



.feature {
    background-color: #fff;
}

.inner {
    width: 80% !important;
    margin: auto;
}

.sectionHeader {
    text-align: center;
}
.sectionHeader p{
    text-align: center;
}

.featureList {
    display: flex;
    flex-direction: column;
    list-style: none;
    row-gap: 8px;
    margin-top: 40px;

}

.featureList li {
    display: flex;
    align-items: flex-start;
    color: var(--font-light);
    font-size: var(--paragraph);
}

.featureList li span {
    margin-right: 5px;
    color: var(--brand-color);
    padding-top: 3px;
}


/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
 
}

/***Responsive CSS***/
@media (max-width: 768px) {
 

    .inner {
        width: 100% !important;
        margin: auto;
    }

}

/***Responsive CSS***/
@media (max-width: 640px) {
 
}