.landingHeroSection {
    padding: 0;
    position: relative;
    z-index: 5;
    /* width: 100%;
    overflow-x: hidden; */
}


.landingHeroInner {
    height: 100%;
    position: relative;
    background-color: rgba(48, 48, 146, 0.6);


}

.slickContainer {}

.sliderItem {
    position: relative;
    height: calc(100vh - var(--header-height));

}

.imageItem {
    position: absolute;
    column-gap: 10px;
    justify-content: space-between;
    display: flex !important;
    align-items: center;
    height: calc(100vh - var(--header-height));
    width: 100%;
    transition: all 1s ease;
    /* flex-wrap: wrap; */
}


.imageWrapper {
    width: 200px;
    flex-grow: 1;
}

.heroImage {
    height: calc(100vh - var(--header-height));
    object-fit: cover;
    width: 100%;
}

.heroTextContainer {
    position: absolute;
    display: inline-block;
    width: 500px;
    margin: auto;
    text-align: center;
    color: #fff;
    transition: transform 1s ease, opacity 1s ease;
    transition-delay: 1s;
    z-index: 10;
    max-width: 100%;

}

.heroTextInner {
    background-color: rgba(48, 48, 146, 0.9);
    padding: 30px 30px;
    border-radius: 10px;


}

.sliderItem p {
    text-align: center;
}

.sliderItem h1 {}

.left {
    position: absolute;
    display: flex;
    justify-content: center;

}

.leftInner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--header-height));
    width: 100%;
    position: relative;

}


.heroTextContainer h1 {
    margin-bottom: 0px;
    color: #fff;
}

.heroTextContainer h1::after {
    left: 0;
    transform: translate(0);
    display: none;
}

.heroTextContainer p {
    color: #fff;
}

.heroButton {
    margin-top: 30px;
}

/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/

@media (max-width: 992px) {
    .imageWrapper {
        width: 30%;
        flex-grow: 1;

    }
}

/***Responsive CSS***/
@media (max-width: 768px) {
    .imageWrapper {
        width: 30%;
        flex-grow: 1;

    }
}

/***Responsive CSS***/
@media (max-width: 640px) {
    .leftInner {
        width: 100%;

    }

    .left {
        position: absolute;
        display: flex;
        justify-content: center;

    }

    .heroTextContainer {
        text-align: center;
    }

    .heroButton {
        display: flex;
        justify-content: center;
    }

    .heroTextInner {
        padding: 10px 10px;
    }

    .imageWrapper {
        width: 40%;
        flex-grow: 1;

    }

    .imageWrapper:nth-child(2) {
        display: none !important;
    }

    .imageWrapper:nth-child(3) {
        display: none !important;
    }
}