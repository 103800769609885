.searchOuter{
    position: relative;
    position: fixed;
    z-index: 5;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    padding: 40px 20px;
}
.cross{
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
}
.searchBox{
    display: flex;
    border: 2px solid var(--brand-color);
    width: 60%;
    margin: auto;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
}
.searchBox input{
    width: calc(100% - 25px);
    padding: 15px 10px;
    background-color: transparent;
    color: #fff;
    border: none;
    outline: none;
}
.searchBox input::placeholder {
    opacity: 0.8;
    color: #fff;
  }
  
.searchBox input:focus-visible {
    border: none;
    outline:  none;
}
.searchIcon{
    color: #fff;
    width: 25px;
}
.searchInner{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    row-gap: 20px;
    
}
.searchItem{
    padding: 15px 10px;
    background-color: transparent;
    color: #fff;
    border: 2px solid var(--brand-color);
    transition: all 500ms ease;
    border-radius: 20px;



}
.searchItem:hover{
    background-color: var(--brand-color);
    color: #fff;
}

@media (max-width: 640px) {
    .searchBox{
        width: 90%;
    }
    .searchInner{
        width: 100%;
    }
    .cross{
        top: 15px;
        font-size: 18px;
        right: 12px;

    }
    
}