

.bodyContainer{
    /* min-height: 100vh; */
    background-color: #fff;
    z-index: 9;

}

.courseFooter{
    width: calc((100% - 250px));
    margin-left: 250px;
}

/* .courseHeader{
    width: calc((100% - 250px));
    margin-left: 250px;
} */




/***Responsive CSS***/
@media (min-width: 1024px) {
    
}

/***Responsive CSS***/
@media (max-width: 992px) {
    .courseFooter{
        margin-left: 0;
        width: 100%;
    }
    /* .courseHeader{
        margin-left: 0;
        width: 100%;
    } */
    
}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    
}

/***Responsive CSS***/
@media (max-width: 448px) {}