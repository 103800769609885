.headerContainer {
  height: var(--header-height);
  background-color: #fff;
  color: #000;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.headerInner {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;

}

.headerTop {
  height: 40%;
  background-color: var(--brand-color);
  width: 100%;

}

.headerTopInner {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.headerBottomInner {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.left {
  display: flex;
  column-gap: 30px;
  height: 100%;
  align-items: center;
}

.leftItem {
  font-size: var(--sub-paragraph);
  color: #fff;
  display: flex;
  column-gap: 4px;
}

.title {}

.info {}

.right {
  display: flex;
  column-gap: 15px;
}

.right a {
  height: 30px;
  width: 30px;
  background-color: #fff;
  color: var(--brand-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.headerBottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 60%;
  align-items: center;
  transition: all 500ms ease;

}
.showBig{
  height: calc(60% * var(--header-height));
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  box-shadow: 0 0 2px 2px #ccc;
}
/* .courseHeader .showBig{
  left: 252px;
  width: calc(100% - 252px);
} */
.headerLeft {
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  height: 100%;
  width: 270px;
  object-fit: contain;
  position: relative;
  top: -1px;
  left: -10px;
}

.headerRight {
  display: flex;
  align-items: center;
  column-gap: 30px;
}
.line{
  height: 30px;
  width: 1px;
  background-color: #DDD;
  display: inline-block;
}

.donateContainer {
  display: flex;
  column-gap: 50px;
  align-items: center;
}

.search {
  cursor: pointer;
}

.searchButton {}







/***Responsive CSS***/
@media (min-width: 1024px) {
  /* .courseHeader .showBig{
    left: 252px;
    width: calc(100% - 252px);
  } */
}
/***Responsive CSS***/

@media (max-width: 992px) {}
/***Responsive CSS***/
@media (max-width: 768px) {
    
   .line{
    display: none;
   }

 
   .headerRight{
    flex-direction: row-reverse;
   }
   .searchButton{
    display: none;
   }
    

}

/***Responsive CSS***/
@media (max-width: 640px) {
  .left{
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
  }
  .right a {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .left {
    align-items: flex-start;
  }
  
  

}
/***Responsive CSS***/
@media (max-width: 448px) {
.right{
  column-gap: 10px;
}
.logo{
  width: 240px;
}
}
