.courseBox{
    background-color: #f6f5f5;

}
.courseSlider{
    position: fixed;
    z-index: 5;
    height: calc(100% - var(--header-height));
    background-color: #f6f5f5;
    padding: 10px 0px 20px;
    top: calc(var(--header-height));
    width: 250px;

}
.courseSlider h2{
    padding: 0 20px;
    color: #000;
    margin-bottom: 20px;
}
.courseChildren{
    margin-left: 250px;
    width: calc(100% - 250px);
}

.courseItem{}
.catHeader{
    padding: 8px 10px;
    border-bottom: 1px solid #cbbebe;
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    color: #000;
    opacity: 0.9;
    line-height: 1.6;
    padding-right: 34px;


}
.catHeader span{
    position: absolute;
    right: 20px;
    font-size: 18px;
}
.selCatHead{
    color: var(--brand-red);
    opacity: 1;
}
.catHeader:hover{
    color: var(--brand-red);

}
.catHeader:last-child{
    border-bottom: none;
}
.catBody{
    transition: all 500ms ease;
    position: relative;
    z-index: 2;
    padding-left: 15px;
    background-color: #4092cda6;

}
.catOn{
    max-height: 1000px;
    opacity: 1;
}
.catOff{
    max-height: 0;
    opacity: 0;
}
.courseName{
    padding: 10px 10px;
    border-bottom: 1px solid #fff;
    display: flex;
    color: #ccc;
    font-size: var(--sub-paragraph);


}
.courseName:last-child{
    border-bottom: none;
}


.selProd{
    color: #000;
}


/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/
@media (max-width: 992px) {
    .courseChildren{
        margin-left: 0;
        width: 100%;
    }
    .courseSlider{
        display: none;
    }
}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    
}

/***Responsive CSS***/
@media (max-width: 448px) {}