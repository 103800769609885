.certificatePage {}

.inner {}

.left {}

.investmentHero {
    padding: 80px 0;
    background-color: var(--bg-light);

}

.investmentHeroInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 30px;
}

.heroLeft {
    flex-basis: calc(50% - 30px);
}

.heroRight {
    flex-basis: 50%;
}

.heroRight img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}

.heroLeft h2::after {
    left: 0;
    transform: translate(0);
}

.investmentForm {
    padding: 80px 0;

}

.investmentFormInner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    flex-direction: column;
}

.investmentFormInner h2 {}

.investmentFormInner p {}

.investmentFormContainer {
    width: 70%;
    margin: auto;
    margin-top: 30px;
}

.investmentFormContainer form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 20px;
}

.inputContainer {}

.inputContainer .required {
    color: red;
    margin-left: 4px;
}

.inputContainer label {
    color: var(--brand-color);
}

.inputContainer .errorText {
    color: red;
    font-size: 12px;
}

.inputContainer input {
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer input:focus-visible {
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}

.inputContainer textarea {
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer textarea:focus-visible {
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}


.right {}

.rightBox {}









/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/
@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {
    .investmentHeroInner {
        flex-direction: column-reverse;
        align-items: flex-start;
        row-gap: 20px;

    }

    .investmentFormContainer {
        width: 100%;
    }

    .investmentHero {
        padding: 30px 0;
    }

    .investmentForm {
        padding: 30px 0;
    }

    .right img {
        height: 300px;
    }
}

/***Responsive CSS***/
@media (max-width: 448px) {}