.requestFormOuter{
}
.header{
    text-align: center;
}
.header h2{
    font-size: var(--sub-header);
}
.header h3{
    font-size: var(--paragraph);

}

.requestFormContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 20px;
    
}
.requestImage{
width: 100%;
height: 200px;
object-fit: contain;
}
.inputContainer {}

.inputContainer input {
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer input:focus-visible {
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}

.inputContainer textarea {
    border: 1px solid #f7eeee;
    width: 100%;
    padding: 10px;
}

.inputContainer textarea:focus-visible {
    border: 1px solid var(--brand-color);
    outline: 1px solid var(--brand-color);
}




/***Responsive CSS***/
@media (min-width: 1024px) {}

/***Responsive CSS***/
@media (max-width: 992px) {}

/***Responsive CSS***/
@media (max-width: 768px) {}

/***Responsive CSS***/
@media (max-width: 640px) {}

/***Responsive CSS***/
@media (max-width: 448px) {}